<template>
	<section id="service-section">
        <div class="jumbotron jumbotron-fluid bg-1 m-0 mb-3 pb-3">
            <div class="container ">
				<div class="row ">

					<div class="col-12 text-center mt-5 pt-5">
						<h3>{{ $t('about_header') }}</h3>
					</div>

					<div class="col-md-12 col-12 text-center">
						<div class="block-text p-3">
							<div class="my-4">
                                <font-awesome-layers class="fa-4x">
                                    <font-awesome-icon icon="fa-solid fa-circle" />
                                    <font-awesome-icon icon="fa-solid fa-book"  transform="shrink-7 left-1" :style="{ color: 'white' }" />
                                </font-awesome-layers>
							</div> 

							<h6>{{ $t('blueseas_pos_title') }}</h6>
							<p class="small" v-html="$t('blueseas_pos_description')"></p>
						</div> 
					</div>

					<div class="col-md-3 col-12 text-center">
						<div class="block-text p-3">
							<div class="my-4">
                                <font-awesome-layers class="fa-4x">
                                    <font-awesome-icon icon="fa-solid fa-circle" />
                                    <font-awesome-icon icon="fa-solid fa-house"  transform="shrink-7 left-1" :style="{ color: 'white' }" />
                                </font-awesome-layers>
							</div> 

							<h6>{{ $t('serene_pos_title') }}</h6>
							<p class="small" v-html="$t('serene_pos_description')"></p>
						</div> 
					</div>

					<div class="col-md-3 col-12 text-center">
						<div class="block-text p-3">
							<div class="my-4">
                                <font-awesome-layers class="fa-4x">
                                    <font-awesome-icon icon="fa-solid fa-circle" />
                                    <font-awesome-icon icon="fa-solid fa-photo-film"  transform="shrink-8 left-2" :style="{ color: 'white' }" />
                                </font-awesome-layers>
							</div>
							
							<h6>{{ $t('gh_pos_title') }}</h6>
							<p class="small" v-html="$t('gh_pos_description')"></p>
						</div> 
					</div>
					

					<div class="col-md-3 col-12 text-center">
						<div class="block-text p-3">
							<div class="my-4">
                                <font-awesome-layers class="fa-4x">
                                    <font-awesome-icon icon="fa-solid fa-circle" />
                                    <font-awesome-icon icon="fa-solid fa-bed"  transform="shrink-8 left-2" :style="{ color: 'white' }" />
                                </font-awesome-layers>
							</div> 

							<h6>{{ $t('webconnection_pos_title') }}</h6>
							<p class="small" v-html="$t('webconnection_pos_description')"></p>
						</div> 
					</div>

					<div class="col-md-3 col-12 text-center">
						<div class="block-text p-3">
							<div class="my-4">
                                <font-awesome-layers class="fa-4x">
                                    <font-awesome-icon icon="fa-solid fa-circle" />
                                    <font-awesome-icon icon="fa-solid fa-graduation-cap"  transform="shrink-8 left-2" :style="{ color: 'white' }" />
                                </font-awesome-layers>
							</div> 

							<h6>{{ $t('graduated_title') }}</h6>
							<p class="small" v-html="$t('graduated_description')"></p>
						</div> 
					</div>

					<!--<div class="col-md-4 col-12 text-center">
						<div class="block-text p-3">
							<div class="my-4">
								<span class="fa-stack fa-2x">
									<i class="fa fa-circle fa-stack-2x iconbg-service"></i>
									<i class="fa fa-handshake fa-stack-1x fa-inverse"></i>
								</span>
							</div> 

							<h6>สหกิจตำแหน่ง WEB DEVELOPER</h6>
							<p class="small">
								บริษัท ภูเก็ต อี มาร์เก็ตติ้ง<br>

								ตุลาคม 2013 - กุมภาพันธ์ 2014
								
								หน้าที่
								
								จัดการสร้าง Website ตามที่บริษัทกำหนด
							</p>
						</div> 
					</div>-->

					
				</div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AboutPage',
    props: {
        
    },
    components: {

    },
    data () {
        return {
        homedata: []
        }
    },
    methods: {

    },
    mounted() {
		document.querySelector(".thisfooter").classList.remove("fixitfooter");
    }
}
</script>
<style>
    .bg-1
    {
		background: rgb(24,100,3);
		background: linear-gradient(90deg, rgba(24,100,3,1) 0%, rgba(59,208,56,1) 100%);
    }
    #service-section
    {
        height: 100vh;
    }
	@media only screen and (max-width: 991px) {
		#service-section
		{
			height: auto;
		}
	}
</style>