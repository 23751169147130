export default {
  "hometitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กิตติเชษฐ พึ่งแรง"])},
  "hometext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จบจาก มหาวิทยาลัยสงขลานครินทร์ วิทยาเขตภูเก็ต คณะ เทคโนโลยีและสิ่งแวดล้อม สาขา เทคโนโลยีสารสนเทศ <br>ใช้ภาษา PHP framework CI หรือ Laravel, Node.js framework Express<br>Database  Mysql,MongoDB <br>จัดการ Frontend โดยใช้ภาษา Html, css, js, jquery, Vue.js react"])},
  "about_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประสบการณ์"])},
  "blueseas_pos_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SENIOR FULLSTACK DEVELOPER"])},
  "blueseas_pos_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Blueseas Enterprise co., ltd.<br>ตุลาคม 2024 - ปัจจุบีน<br><b>หน้าที่</b><br>จัดการ Website และ Application ต่างๆของบริษัท ด้วย ReactJs และ Node.js และ MA โปรเจคเก่าที่ใช้ภาษาอื่นๆเช่น Laravel"])},
  "serene_pos_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WEB AND APPLICATION DEVELOPER"])},
  "serene_pos_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Serene Property And Development Co., LTD.<br>กุมภาพันธ์ 2562 - กรกฎาคม 2567<br><b>หน้าที่</b><br>จัดการ Website และ Application ต่างๆของบริษัท โดยใช้ภาษาหลักคือ PHP และใช้ API เชื่อมต่อภายนอกเช่น Line Official Account"])},
  "gh_pos_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WEB AND UNITY DEVELOPER"])},
  "gh_pos_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Groundhog studio<br>กรกฎาคม 2558 - กุมภาพันธ์ 2562<br><b>หน้าที่</b><br>จัดการและดูแล Website (PHP), จัดการ API ที่เชื่อมต่อกับ Application เขียนโปรแกรม Unity โดยใช้ภาษา C# และ Publish IOS และ Android Application ขึ้น Store"])},
  "webconnection_pos_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WEB DEVELOPER"])},
  "webconnection_pos_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Webconnection<br>มีนาคม 2557 - มีนาคม 2558<br><b>หน้าที่</b><br>จัดการเว็บไซต์โรงแรม โดยนำ Frontend ที่ได้ออกแบบมาใส่ระบบ Backend ที่ทางบริษัทออกแบบไว้ และปรับปรุงระบบต่างๆให้ดีขึ้น"])},
  "graduated_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จบการศึกษา"])},
  "graduated_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มหาวิทยาลัยสงขลานครินทร์ วิทยาเขตภูเก็ต<br>2553 - 2557<br>จบศึกษาระดับปริญญาตรี <br>สาขา เทคโนโลยีสารสนเทศ (IT) GPA 3.78"])},
  "serenewebsite_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serene Website"])},
  "serenewebsite_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>responsibility</b><br>- แก้ไข Webdesign จากที่ได้รับออกแบบมา<br>- จัดการระบบหลังบ้านให้ เพิ่ม ข่าวสารได้<br>- จัดการระบบหลังบ้านให้ แก้ไข สไลด์ได้<br>- จัดการการส่ง Email ติดต่อเรา<br><br><b>tech</b><br>PHP(CI3) Html CSS JS Jquery"])},
  "sereneprojectwebsite_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serene Website โครงการทั้งหมด"])},
  "sereneprojectwebsite_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>responsibility</b> <br>- แก้ไข Webdesign จากที่ได้รับออกแบบมา<br>- จัดการระบบหลังบ้านให้ แก้ไข สไลด์ได้<br>- จัดการรูป 360 และส่วนต่างๆบนหน้า Website<br>- จัดการการส่ง Email ติดต่อเรา<br><br><b>tech</b><br>PHP(CI3) Html CSS JS Jquery  360 Library"])},
  "akirawebsite_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akira Resident Website"])},
  "akirawebsite_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- แก้ไข Webdesign จากที่ได้รับออกแบบมา<br><br>"])},
  "spmwebsite_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบเก็บเงินห้องรายเดือน"])},
  "spmwebsite_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- จัดการระบบเพิ่มห้องที่มีคนมาจองห้องพัก<br>- จัดการระบบเพิ่มบิลเพื่อเก็บเงินผู้พัก<br>- จัดการระบบบิลเพื่อให้ผู้พักหลังจากระระเงินเรียบร้อย<br><br>"])},
  "itticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบแจ้งซ่อม IT ภายในบริษัท"])},
  "itticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- จัดการระบบเพิ่มการแจ้งซ่อมสำหรับงาน IT ภายในบริษัท<br>- จัดการระบบรับงาน หลังจากที่ผู้ใช้งานแจ้ง<br>- จัดการinhouseงานหลังจากแก้ไขเรียบร้อยแล้ว<br>- จัดการระบบให้คะแนนการแก้ไขงาน<br><br>"])},
  "pluscondoconnect_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus Condo Connect"])},
  "pluscondoconnect_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- ใช้ดูสัญญาของการเช่าคอนโด<br>- ใช้ชำระเงินห้องของลูกค้าผ่าน QR Code / SCB Easy โดยใช้ระบบจาก SCB และผ่านบัตรเครดิตโดย Omise  <br>- ดูการนัดหมายต่างๆในระบบ<br>- ดูความคืบหน้าการก่อสร้าง<br>- ดูช่องทางการติดต่อของ Condo<br>"])},
  "assetsweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบทรัพย์สินของบริษัท"])},
  "assetsweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- จัดการระบบเทรัพย์สินภายในบริษัท<br>- จัดการระบบแจ้ง/อนุมัติทรัพย์สินที่ต้องการซ่อม / ย้าย / จำหน่าย ไปยังหัวหน้าของผู้รับผิดชอบ<br>- จัดการ QRCode เพื่อตรวจสอบทรัพย์สินได้<br>- ทำรายงานทรัพย์สิน<br><br>"])},
  "appealweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบแจ้งเรื่องร้องเรียน"])},
  "appealweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- จัดการระบบแจ้งเรื่องร้องเรียนคนในบริษัทไปยังหัวหน้า / ผู้บริหาร<br>- จัดการส่ง Email แบบเป็นความลับ<br><br>"])},
  "rmsweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบงานขาย"])},
  "rmsweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- จัดการเพิ่มเติม / แก้ไข Module ต่างๆที่ได้รับมอบหมาย<br>- แก้ไข Bug หรือ ข้อมูลต่างๆตามที่ผู้ใช้งานแจ้งมา<br><br>"])},
  "fmweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบงานซ่อม"])},
  "fmweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- จัดการเพิ่มเติม / แก้ไข Module ต่างๆที่ได้รับมอบหมาย<br>- แก้ไข Bug หรือ ข้อมูลต่างๆตามที่ผู้ใช้งานแจ้งมา<br><br>"])},
  "authen_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Authentication"])},
  "authen_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- เพิ่ม Authentication ผ่าน Line OA แจ้งไปยัง Line ของพนักงานเพื่อเข้าใช้งานระบบของบริษัททุกระบบ<br><br><br>"])},
  "vrboothweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VR Booth Online"])},
  "vrboothweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- แก้ไข Module ต่างๆ ตามที่ได้รับมอบหมาย<br><br><br>"])},
  "warnweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เขียนแจ้งเตือนประจำวัน / Backup"])},
  "warnweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- เขียน Cronjob แจ้งเตือนเรื่องต่างๆเช่น นัดลูกค้า รายการซ่อมคงค้าง<br>- เขียน Backup ข้อมูลรายวันเพื่อเชื่อมต่อกับที่เก็บข้อมูลภายในบริษัท<br><br>"])},
  "questionnaireweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบแบบสอบถาม"])},
  "questionnaireweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- ทำแบบสอบถามลูกค้าให้ใช้บน Ipad เป็น PWA<br><br>"])},
  "followrunner_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Followrunner"])},
  "followrunner_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- จัดการระบบหลังบ้าน และหน้า Website <br>- จัดการระบบขายสินค้า <br>- จัดการระบบสมัครวิ่ง <br>- จัดการระบบ Payment (Paypal)<br><br><br><br>"])},
  "krabiweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ศูนย์ประสานงานโครงการพัฒนาอันเนื่องมาจากพระราชดำริ จังหวัดกระบี่"])},
  "krabiweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- จัดการระบบหลังบ้าน และหน้า Website ตามที่ลูกค้าต้องการ<br><br><br><br><br>"])},
  "eyetestweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eyestesting Bangkok Hospital Phuket"])},
  "eyetestweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- จัดทำระบบตอบคำถามของลูกค้า ที่มีความสงสัยเรื่องตาว่าปกติหรือไม่<br>- จัดทำระบบตรวจสอบตาบอดสี<br>- จัดทำ Website ให้ความรู้เรื่องต่างๆเกี่ยวกับตา<br>- จัดทำรายงานในรูปแบบตาราง และ กราฟ<br>"])},
  "localfoodweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHUKET LOCAL FOOD"])},
  "localfoodweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- จัดทำ Website แสดงข้อมูลของกินในพื้นที่จังหวัดภูเก็ต<br>- ส่ง API ให้กับ Application<br><br><br><br><br><br><br><br>"])},
  "panyaandamanweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปัญญาอันดามัน"])},
  "panyaandamanweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- แก้ไขระบบหลังบ้าน และ หน้าบ้านบางส่วน<br>- เพิ่มเติมการจัดการ video และ เสียง ส่วนของ backend<br><br><br><br><br><br><br><br>"])},
  "jdpoolsweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JDPOOLSWEBSERVICE"])},
  "jdpoolsweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- ระบบของฝ่ายขายเมื่อไปขายสระ โดยจะเก็บความต้องการของลูกค้า<br>- ออกรายงาน และ จัดทำรายงานต่างๆในรูปแบบ กราฟ และ ตาราง<br>- นำราคาสินค้าต่างๆเข้าระบบโดยผ่าน excel และ export เป็น excel<br>- export รายงานเป็น PDF<br>- จัดทำรายงานประจำสัปดาห์ และ ส่งทาง email<br>- จัดการยอดขายจริงและแสดงรายงาน<br>"])},
  "cracycat_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cracycat"])},
  "cracycat_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- จัดทำระบบ นามบัตร ออนไลน์<br>- จัดทำระบบ Pdf Online<br><br><br>"])},
  "canvaskids_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แคนวาสคิดส์"])},
  "canvaskids_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- Website หลักของ Product Canvas Kids<br>- จัดทำระบบเพื่อ โหลด ไฟล์รูปภาพเพื่อนำไประบายสีส่องกับ Application<br>- ส่ง API product ให้กับ Application<br>"])},
  "ghweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กราวฮอกสตูดิโอ"])},
  "ghweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- Parallax website<br>- Website โชว์ port การติดต่อ และผลงานของบริษัท<br><br><br>"])},
  "bpkarapp_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BPKAR APPLICATION"])},
  "bpkarapp_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- จัดทำระบบถ่ายรูป<br>- จัดการระบบ upload รูปขึ้น server<br>- จัดการระบบ Share Facebook<br>- Upload ขึ้น Store IOS และ Android <br><br>"])},
  "vrkrabiapp_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APPLICATION VRKRABI"])},
  "vrkrabiapp_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- จัดทำระบบ upload ข้อมูลต่างๆและส่ง API <br>- ดึงข้อมูล API โดยใช้ Json <br>- แสดงข้อมูลต่างๆ จาก API บน Application <br>- Upload ขึ้น Store IOS และ Android <br><br>"])},
  "canvaskidsapp_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APPLICATION CANVAS KIDS"])},
  "canvaskidsapp_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- จัดการระบบบน Store เพื่อขายสินค้า In App <br>- จัดทำระบบ upload ข้อมูลต่างๆและส่ง API <br>- ดึงข้อมูล API โดยใช้ Json <br>- แสดงข้อมูลต่างๆ จาก API บน Application <br>- Upload ขึ้น Store IOS และ Android <br>"])},
  "smartandamanapp_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ANDAMAN APPLICATION"])},
  "smartandamanapp_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- จัดทำระบบการควบคุมด้วยมือ(บางส่วน) <br>- จัดทำ Gallery รูป <br>- จัดการแสดงข้อมูลเนื้อหาต่างๆบน Application <br><br><br>"])},
  "keehinweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กี่หิ้น"])},
  "keehinweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br> - จัดการระบบโชว์สินค้า<br>- จัดการระบบหลังบ้านให้ เพิ่ม ลบ สินค้า, หมวดหมู่ ได้<br>- จัดการระบบหน้า page ให้สามารถแก้ไขได้<br>- ทำ SEO<br><br>"])},
  "otherweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["งานอื่นๆ"])},
  "otherweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b>  <br>- จัดการระบบ Website ทั้งหมด/บางส่วนตามที่ลูกค้าต้องการ<br>- ไม่สามารถเปิดเผยได้หลายๆงาน เนื่องจากลูกค้าไม่ต้องการเปิดเผย<br><br>"])}
}